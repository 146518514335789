import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import Main from "../components/main"
import MenuCats from "../components/menucats"
import MenuTags from "../components/menutags"
import MenuAll from "../components/menuall"
import Text from "../components/text"
import TextMin from "../components/textmin"
import { Heading, Styled } from "theme-ui"
import SEO from "../components/seo"

/*
 * Esta es la forma abreviada de declarar un componente
 * Fijate que usa {} por que tiene variables dentro
 * Luego hacer un return.
 * Mira comentarios en archivo layout
 *
 */
export default ({ data }) => {

  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
      <Header page='frontpage'>
        <Heading as={`h1`}>{data.site.siteMetadata.title}</Heading>
      </Header>
      <Main>
        <Text
          textcolor='black'
          bgcolor='white'
        >
          <Heading as={`h2`}>Sobre</Heading>
          <Styled.p>El mapa de projectes socials té com a finalitat:</Styled.p>
          <Styled.ul>
            <Styled.li>Contribuir a visualitzar els projectes que duen a terme les biblioteques públiques per a la millora social del seu entorn o comunitat.</Styled.li>
            <Styled.li>Mostrar al tercer sector les possibilitats d´actuacions conjuntes en el territori.</Styled.li>
            <Styled.li>Evidenciar a les administracions públiques la necessitat de seguir invertint en las biblioteques públiques.</Styled.li>
            <Styled.li>Potenciar l´intercanvi de coneixement i aprendre de les "millors pràctiques".</Styled.li>
          </Styled.ul>
          <Styled.p><strong>Nota</strong>: <em>Cada projecte és en la llengua en què s´ha realitzat: català o castellà</em>.</Styled.p>
        </Text>
        <MenuCats />
        <MenuTags />
        <MenuAll />
        <TextMin
          textcolor='black'
          bgcolor='gray.1'
        >
          <Styled.p>Cada any incorporem els projectes millor avaluats a la convocatòria del Premi "Biblioteca Pública i compromís social". En el mapa destaquem els projectes acceptats, els finalistes i els guanyadors. Dels guanyadors i finalistes hi ha un enllaç al projecte.</Styled.p>
          <Styled.p>Knowledge map of public library projects for combating social exclusion.<br/> <Styled.a as={Link} to={`/convocatoria-2014`}>First edition (2014)</Styled.a> was supported by a grant from the PL2020 Programme of the R&W.</Styled.p>
        </TextMin>
      </Main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query frontPage {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
