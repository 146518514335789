import React from "react"
import { Box } from "theme-ui"

/*
 * Esta es la forma extra abreviada de declarar un componente.
 * No lleva ni nombre de componente.
 * Fijate que no usa {} por que no hay variables en el componente.
 * Fijate que no usa return.
 * Mira comentarios en archivo layout
 *
 */
export default ( props ) => {

  const textColor = props.textcolor
  const bgColor = props.bgcolor

  return (
    <Box
      as={`section`}
      sx={{
        backgroundColor: `${bgColor}`,
        my: 4,
        pl: [3, 3, 3, 4, 4],
        pr: [3, 3, 3, 0, 0],
        "h2, h3, h4, p, ul, li": {
          color: `${textColor}`,
          maxWidth: [`initial`, `46rem`],
        },
        "h2, h3, h4": {
          fontSize: 0,
          fontWeight: `body`,
          mb: 3,
          "&:after": {
            backgroundColor: `${textColor}`,
            content: `""`,
            display: `block`,
            height: `1px`,
            width: 6,
            my: 2,
          },
        },
        ul: {
          fontSize: [1, 2],
        },
        p: {
          mt: 0,
        }
      }}
    >
      {props.children}
    </Box>
  )
}
